import React from 'react';
import { withSize } from 'react-sizeme';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import Heading from '../components/Heading';
import SEO from '../components/SEO';

import pipesImgDesktop from '../images/pipesDesktop.jpg';
import pipesImgDesktopx2 from '../images/pipesDesktopx2.jpg';
import aboutHeroLarge from '../images/about-hero-large.jpg';
import SubPageHeader from '../components/SubPageHeader';
import ContactForm from '../components/ContactForm/ContactForm';
import AboutStyled from '../components/page-styles/about-styles';

const AboutPage = ({ path, size }) => (
    <Layout path={path}>
        <SEO title="About Us | Saratoga" />
        <AboutStyled>
            <section>
                <SubPageHeader
                    text="A leading provider of energy products and services."
                    bgImage={aboutHeroLarge}
                ></SubPageHeader>
            </section>
            <section className="about">
                <Heading
                    text="Safety, Efficiency, & Security of Supply with Millions in the Pipeline"
                    headingType="h2"
                ></Heading>

                <p>
                    The success of your logistics is dependent on a reliable,
                    stable, and efficient fuel supplier that understands your
                    unique needs and works with you to fulfill them. Our culture
                    of excellence has driven us to create an expansive and
                    secure product network with international pipeline and rail
                    access, manufacturing and refining capability, and upstream
                    partnerships that give you confidence you won’t be left high
                    and dry when it comes to fuel supply. Our team is committed
                    to building personal and longstanding relationships, and is
                    standing by to ensure that your needs are met.
                </p>
            </section>
            <section className="our-mission">
                <div className="background">
                    <img
                        src={
                            size.width < 700
                                ? pipesImgDesktop
                                : pipesImgDesktopx2
                        }
                        alt="our mission backgroud "
                    />
                </div>
                <div className="red-box">
                    <Heading
                        text="Our Mission"
                        color="#fff"
                        headingType="h2"
                    ></Heading>
                    <p>
                        Saratoga is committed to bringing value to your business
                        by efficiently meeting your fuel needs thanks to our
                        extensive supply network of diesel, gasoline, DEF,
                        ethanol, and other fuel products.
                    </p>
                </div>
            </section>
            <ContactForm id="contact" />
        </AboutStyled>
    </Layout>
);

AboutPage.propTypes = {
    size: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};

export default withSize()(AboutPage);
