import styled from 'styled-components';

const AboutStyled = styled.div`
    .about {
        padding: 40px;
        width: 100%;
        background-color: #fff;
        text-align: left;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding-bottom: 60px;

        @media screen and (min-width: 1400px) {
            flex-direction: row;
            padding-left: 200px;
            padding-right: 200px;
            h2,
            p {
                flex-grow: 1;
                flex-basis: 0;
            }

            p {
                padding-left: 30px;
            }
        }
    }

    p {
        font-size: 18px;
        color: #4a4f53;
        line-height: 36px;
    }

    .our-mission {
        position: relative;

        .background {
            overflow: hidden;
        }
        img {
            width: 100vw;
            height: 428px;
        }
    }

    .red-box {
        position: absolute;
        margin-right: 15px;
        margin-left: 15px;
        top: -45px;
        background-color: #be3a34;
        opacity: 0.8;
        text-align: center;
        padding: 100px 25px;
        height: 528px;
        box-sizing: border-box;

        p {
            color: #fff;
        }

        @media screen and (min-width: 700px) {
            margin-left: 60px;
            margin-right: 60px;
            padding: 80px 160px;

            p {
                font-size: 20px;
            }
        }

        @media screen and (min-width: 1400px) {
            margin-left: 130px;
            margin-right: 130px;
            padding: 130px 280px;

            p {
                font-size: 24px;
                line-height: 40px;
            }
        }
    }
`;

export default AboutStyled;
